import { useState } from "react";
import "./textToSpeech.scss";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import ReplayIcon from "@mui/icons-material/Replay";
import ReactionBoard from "./ReactionBoard/ReactionBoard";
import StoredTexts from "./StoredTexts/StoredTexts";
import { db } from "../../services/db";
import { useLiveQuery } from "dexie-react-hooks";
import getRelevantTexts from "../../utilities/getRelevantTexts";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { speakText } from "../../services/polly";

type TextToSpeechProps = {
	selectedTag: string | null;
	setSelectedTag: React.Dispatch<React.SetStateAction<string | null>>;
};

const TextToSpeech = ({ selectedTag, setSelectedTag }: TextToSpeechProps) => {
	const [text, setText] = useState("");
	const [headline, setHeadline] = useState<string | null>(null);
	// determines whether a shortcut button is use to speak an already saved text or save a new one
	const [buttonMode, setButtonMode] = useState<"speak" | "save">("speak");

	const storedTexts = useLiveQuery(() => db.texts.toArray());
	const relevantTexts = getRelevantTexts(storedTexts, selectedTag);

	const handleChange = (e: any) => {
		const [text, headline] = e.target.value.split("$").reverse();
		setText(text);
		setHeadline(headline);
	};

	const handleClick = () => {
		speakText(text);
	};

	const handleAddText = () => (buttonMode === "speak" ? setButtonMode("save") : setButtonMode("speak"));
	return (
		<>
			<div className="tts-area-wrapper">
				<textarea className="tts-area" onChange={handleChange} aria-label="text-to-speech text field" value={text} />
			</div>

			<div className="playButtonWrapper">
				<PlayCircleFilledWhiteIcon className="playButton" fontSize="inherit" onClick={handleClick} />
				<ReplayIcon fontSize="inherit" />
				<AddCircleIcon fontSize="inherit" onClick={handleAddText} />
			</div>
			{relevantTexts?.length ? (
				<StoredTexts texts={relevantTexts} textSetter={{ setText, setHeadline }} />
			) : (
				<ReactionBoard
					headline={headline}
					text={text}
					setSelectedTag={setSelectedTag}
					storedTexts={storedTexts}
					buttonMode={buttonMode}
				/>
			)}
		</>
	);
};

export default TextToSpeech;
