import { voiceOptions } from "../../services/polly";
import "./options.scss";
import { putOptions, Option } from "../../services/db";
import getValueOfStoredOption from "../../utilities/getValueOfStoredOption";

type OptionsProps = {
	storedOptions: Option[] | undefined;
};

const Options = ({ storedOptions }: OptionsProps) => {
	const handleFormChange = (e: any) => {
		if (storedOptions?.length) {
			const filterCallBack = (option: Option) => option.name === e.target.id;
			const updatedOption = storedOptions?.find(filterCallBack);
			if (updatedOption) {
				updatedOption.value = e.target.value;
				const otherOptions = storedOptions?.filter((option) => !filterCallBack(option));
				putOptions([...otherOptions, updatedOption]);
				return;
			}
		}
		putOptions([{ name: e.target.id, value: e.target.value }]);
	};

	return (
		<div className="options-wrapper">
			<h1>Options</h1>
			<form className="options-form" onChange={handleFormChange} onSubmit={(e) => e.preventDefault()}>
				<h3 className="options-category">Access Keys</h3>
				<label htmlFor="amazonkey" aria-label="Amazon API Key">
					Amazon
				</label>
				<input type="text" id="amazonKey" defaultValue={getValueOfStoredOption(storedOptions, "amazonKey")} />
				<label htmlFor="typewisekey">Typewise</label>
				<input
					type="text"
					id="typewiseKey"
					aria-label="Typewise API Key"
					defaultValue={getValueOfStoredOption(storedOptions, "typewiseKey")}
				/>
				<h3 className="optionsCategory">Voice Settings</h3>
				<select
					id="voiceType"
					aria-label="Synthetic voice used"
					value={getValueOfStoredOption(storedOptions, "voiceType")}
				>
					{voiceOptions.map((option, index) => (
						<option key={index} value={option.value}>
							{option.label}
						</option>
					))}
				</select>

				<input type="submit" className="options-form-submit" value="Save" />
			</form>
		</div>
	);
};

export default Options;
