import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { deleteText, Text } from "../../../services/db";
import { speakText } from "../../../services/polly";
type StoredTextProps = {
	textEntry: Text;
	textSetter: {
		setText: React.Dispatch<React.SetStateAction<string>>;
		setHeadline: React.Dispatch<React.SetStateAction<string | null>>;
	};
};

const StoredTextEntry = ({ textEntry, textSetter }: StoredTextProps) => {
	const handlePlayClick = () => speakText(textEntry.text);

	const handleTextClick = () => {
		console.log("click");
		textSetter.setText(textEntry.text);
	};

	const handleDeleteClick = () => deleteText(textEntry.id);

	return (
		<div className="stored-text-entry-wrapper">
			<div onClick={handlePlayClick} className="icon-wrapper" aria-label="play entry">
				<PlayCircleIcon />
			</div>
			<div className="text" onClick={handleTextClick}>
				{textEntry.text}
			</div>
			<div onClick={handleDeleteClick} className="icon-wrapper" aria-label="delete entry">
				<HighlightOffIcon onClick={handleDeleteClick} />
			</div>
		</div>
	);
};

export default StoredTextEntry;
