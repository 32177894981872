import StoredTextEntry from "./StoredTextEntry";
import "./storedTexts.scss";
import { Text } from "../../../services/db";

type StoredTextsProps = {
	texts?: Text[];
	textSetter: {
		setText: React.Dispatch<React.SetStateAction<string>>;
		setHeadline: React.Dispatch<React.SetStateAction<string | null>>;
	};
};

const StoredTexts = ({ texts, textSetter }: StoredTextsProps) => {
	return (
		<div className="stored-texts-wrapper">
			{texts &&
				texts.map((textEntry: Text) => (
					<StoredTextEntry textEntry={textEntry} key={textEntry.id} textSetter={textSetter} />
				))}
		</div>
	);
};

export default StoredTexts;
