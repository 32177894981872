import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { Polly } from "@aws-sdk/client-polly";
import { getSynthesizeSpeechUrl } from "@aws-sdk/polly-request-presigner";
import synthesizeLocally from "./browserTTS";
import { getOptions } from "./db";
import getValueOfStoredOption from "../utilities/getValueOfStoredOption";

// Create the Polly service client, assigning your credentials
let client: Polly;
getOptions().then((options) => {
	client = new Polly({
		region: "us-east-1",
		credentials: fromCognitoIdentityPool({
			client: new CognitoIdentityClient({ region: "us-east-1" }),
			identityPoolId: getValueOfStoredOption(options, "amazonKey"), // IDENTITY_POOL_ID
		}),
	});
});

const voiceOptions = [
	{ engine: "neural", voiceId: "Daniel", language: "de", value: "deNeural", label: "Daniel DE Neural" },
	{ engine: "standard", voiceId: "Hans", language: "de", value: "deStandard", label: "Hans DE" },
	{ engine: "neural", voiceId: "Matthew", language: "en", value: "enNeural", label: "Matthew EN Neural" },
	{ engine: "standard", voiceId: "Matthew", language: "en", value: "enStandard", label: "Matthew EN" },
	{ engine: "standard", voiceId: "Offline", language: "de", value: "offline", label: "Offline DE" },
];

// Set the parameters
const speechParams = {
	Engine: "",
	OutputFormat: "mp3", // For example, 'mp3'
	SampleRate: "16000", // For example, '16000
	Text: "", // The 'speakText' function supplies this value
	TextType: "text", // For example, "text"
	VoiceId: "", // For example, "Matthew"
};

const speakText = async (text: string) => {
	// Update the Text parameter with the text entered by the user
	try {
		const options = await getOptions();
		const voiceOptionValue = getValueOfStoredOption(options, "voiceType");

		const relevantOption = voiceOptions.find((option) => option.value === voiceOptionValue);

		if (relevantOption?.value === "offline") return synthesizeLocally(text, "de");

		speechParams.Text = text;
		speechParams.VoiceId = relevantOption ? relevantOption.voiceId : "Daniel";
		speechParams.Engine = relevantOption ? relevantOption.engine : "";

		let url = await getSynthesizeSpeechUrl({
			client,
			params: speechParams,
		});

		const audio = new Audio(url as string);

		audio.play();
	} catch (_err) {
		synthesizeLocally(text, "de");
	}
};

export { speakText, voiceOptions };
