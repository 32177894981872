import classNames from "classnames";

type ReactionButtonProps = {
	emoji: JSX.Element;
	tag: string;
	onClick: (tag: string) => () => void;
	countRelevantText: number | undefined;
	saveMode: boolean;
};

const ReactionButton = ({ emoji, tag, onClick, countRelevantText, saveMode }: ReactionButtonProps) => (
	<div className={classNames("reaction-button", { "save-mode": saveMode })}>
		<span onClick={onClick(tag)}>{emoji}</span>
		<div className="reaction-button-badge">{countRelevantText}</div>
	</div>
);

const reactionBoardContent = [
	{
		emoji: <div>❤️</div>,
		tag: "favorite_1",
	},
	{
		emoji: <div>🧡</div>,
		tag: "favorite_2",
	},
	{
		emoji: <div>💛</div>,
		tag: "favorite_3",
	},
	{
		emoji: <div>💚</div>,
		tag: "favorite_4",
	},
	{
		emoji: <div>💙</div>,
		tag: "favorite_5",
	},
	{
		emoji: <div>😀</div>,
		tag: "happy",
	},
	{
		emoji: <div>🤬</div>,
		tag: "curse",
	},
	{
		emoji: <div>🤢</div>,
		tag: "sick",
	},
	{
		emoji: <div>😍</div>,
		tag: "in_love",
	},
	{
		emoji: <div>🐶</div>,
		tag: "animals",
	},
	{
		emoji: <div>🥛</div>,
		tag: "drinks",
	},
	{
		emoji: <div>🍽</div>,
		tag: "food",
	},
	{
		emoji: <div>🕑</div>,
		tag: "time",
	},
	{
		emoji: <div>❓</div>,
		tag: "questions",
	},
	{
		emoji: <div>🙏</div>,
		tag: "thanks",
	},
	{
		emoji: <div>😴</div>,
		tag: "exhausted",
	},
	{
		emoji: <div>😯</div>,
		tag: "suprised",
	},
	{
		emoji: <div>🤔</div>,
		tag: "thinking",
	},
	{
		emoji: <div>🥳</div>,
		tag: "party",
	},
	{
		emoji: <div>😈</div>,
		tag: "evil",
	},
	{
		emoji: <div>😻</div>,
		tag: "love",
	},
	{
		emoji: <div>🤯</div>,
		tag: "explanations",
	},
	{
		emoji: <div>🚊</div>,
		tag: "travel",
	},
	{
		emoji: <div>🎮</div>,
		tag: "gaming",
	},
	{
		emoji: <div>🆘</div>,
		tag: "sos",
	},
];

export { reactionBoardContent, ReactionButton };
