import { URL_PREFERENCES, URL_HOME, URL_SAVED_TEXTS, URL_RECENT_TEXTS } from "../../vars/constants";
import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import RestoreIcon from "@mui/icons-material/Restore";
import "./navigation.scss";
import { Link } from "react-router-dom";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import { useLocation } from "react-router-dom";

const navButtonOptions = [
	{ url: URL_HOME, icon: <RecordVoiceOverIcon />, aria: "Text-to-Speech" },
	{ url: URL_RECENT_TEXTS, icon: <RestoreIcon />, aria: "Recent texts" },
	{ url: URL_SAVED_TEXTS, icon: <FolderOpenIcon />, aria: "Saved texts" },
	{ url: URL_PREFERENCES, icon: <SettingsIcon />, aria: "Settings" },
];

const isSelected = (navUrl: string, currentUrl: string) => navUrl === currentUrl;

type NaviagationProps = {
	setSelectedTag: React.Dispatch<React.SetStateAction<null | string>>;
};

const Navigation = ({ setSelectedTag }: NaviagationProps) => {
	const { pathname: currentUrl } = useLocation();

	const resetSelection = () => setSelectedTag(null);

	return (
		<div className="navBar">
			{navButtonOptions.map((option, index) => {
				return (
					<Link
						onClick={resetSelection}
						className="navButton"
						key={index}
						to={option.url}
						aria-label={option.aria}
						id={isSelected(option.url, currentUrl) ? "isSelected" : undefined}
					>
						{option.icon}
					</Link>
				);
			})}
		</div>
	);
};

export default Navigation;
