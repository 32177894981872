import { reactionBoardContent, ReactionButton } from "./ReactionButton";
import "./reactionBoard.scss";
import { addText, Text } from "../../../services/db";
import getRelevantTexts from "../../../utilities/getRelevantTexts";
import React from "react";

type ReactionBoardProps = {
	text: Text["text"];
	headline: Text["headline"];
	setSelectedTag: React.Dispatch<React.SetStateAction<string | null>>;
	storedTexts: Text[] | undefined;
	buttonMode: "speak" | "save";
};

const ReactionBoard = ({ text, headline, setSelectedTag, storedTexts, buttonMode }: ReactionBoardProps) => {
	const handleReactionButtonClick = (tag: string) => () => {
		(async () => {
			buttonMode === "speak" ? setSelectedTag(tag) : addText(headline, text, tag);
		})();
	};

	return (
		<div className="reaction-board">
			{reactionBoardContent.map((content, i) => {
				const countRelevantText = getRelevantTexts(storedTexts, content.tag)?.length;
				return (
					<ReactionButton
						saveMode={buttonMode === "save"}
						tag={content.tag}
						emoji={content.emoji}
						onClick={handleReactionButtonClick}
						key={i}
						countRelevantText={countRelevantText}
					/>
				);
			})}
		</div>
	);
};

export default ReactionBoard;
