const voiceName = {
	de: "Microsoft Stefan - German (Germany)",
	en: "Google UK English Male",
};

const synthesizeLocally = (text: string, language: keyof typeof voiceName) => {
	if ("speechSynthesis" in window) {
		var synthesis = window.speechSynthesis;

		// Get the first `en` language voice in the list
		var voice = synthesis.getVoices().filter(function (voice) {
			return voice.name === voiceName[language];
		})[0];

		// Create an utterance object
		var utterance = new SpeechSynthesisUtterance(text);

		// Set utterance properties
		utterance.voice = voice;

		// Speak the utterance
		synthesis.speak(utterance);
	} else {
		console.log("Text-to-speech not supported.");
	}
};

export default synthesizeLocally;
