import Dexie, { Table } from "dexie";

export type OptionValues = {
	amazonKey: string;
	typewiseKey: string;
	voiceType: string;
};

export interface Option {
	id?: number;
	name: string;
	value: string;
}

export interface Text {
	id?: number;
	tag: string;
	text: string;
	headline: string | null;
}

const errorLogger = (error: unknown) => console.log(`Oops something went wrong: ${error}`);
export class MySubClassedDexie extends Dexie {
	// 'keys' is added by dexie when declaring the stores()
	// We just tell the typing system this is the case
	options!: Table<Option>;
	texts!: Table<Text>;

	constructor() {
		super("patricipate");
		this.version(5).stores({
			options: "++id, name, value",
			texts: "++id, tag, text, headline",
		});
	}
}

const db = new MySubClassedDexie();

const addText = async (headline: Text["headline"], text: Text["text"], tag: Text["tag"]) => {
	if (!text) return;
	try {
		const id = await db.texts.add({ tag, text, headline });
		console.log({ tag, id, text, headline });
	} catch (error) {
		errorLogger(error);
	}
};

const getTexts = async (tag: Text["tag"]) => {
	try {
		const texts = await db.texts.where("tag").equals(tag).toArray();
		console.log(texts);
		return texts;
	} catch (error) {
		errorLogger(error);
	}
};

const deleteText = async (id: Text["id"]) => {
	try {
		const deleteCount = id && (await db.texts.delete(id));
		console.log("Deleted " + deleteCount + " objects");
	} catch (e) {
		errorLogger(e);
	}
};

const putOptions = async (options: Option[]) => {
	try {
		options.map(({ id, name, value }) => {
			return id ? db.options.put({ id, name, value }) : db.options.add({ name, value });
		});
	} catch (error) {
		errorLogger(error);
	}
};

const getOptions = async () => {
	try {
		const options = await db.options.toArray();
		console.log(options);
		return options;
	} catch (error) {
		errorLogger(error);
	}
};

export { db, addText, getTexts, deleteText, putOptions, getOptions };
