import { BrowserRouter, Routes, Route } from "react-router-dom";
import { URL_PREFERENCES, URL_HOME } from "./vars/constants";
import { useState } from "react";

import Navigation from "./components/Navigation/Navigation";
import Options from "./pages/Options/Options";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import TextToSpeech from "./pages/TextToSpeech/TextToSpeech";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "./services/db";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./vars/MUITheme/theme";
import "./app.scss";

function App() {
	const [selectedTag, setSelectedTag] = useState<string | null>(null);
	const storedOptions = useLiveQuery(() => db.options.toArray());

	if (!window.indexedDB) {
		return <div>This application requires a browser supporting indexedDB.</div>;
	}

	return (
		<ThemeProvider theme={theme}>
			<div className="App">
				<div className="logo">Patricipate</div>
				<BrowserRouter>
					<Routes>
						<Route
							path={URL_HOME}
							element={<TextToSpeech selectedTag={selectedTag} setSelectedTag={setSelectedTag} />}
						/>
						<Route path={URL_PREFERENCES} element={<Options storedOptions={storedOptions} />} />
						<Route path="*" element={<PageNotFound />} />
					</Routes>
					<Navigation setSelectedTag={setSelectedTag} />
				</BrowserRouter>
			</div>
		</ThemeProvider>
	);
}

export default App;
